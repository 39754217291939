import React, { Component } from 'react';
import './Footer.scss'
import section5bottombg from '../../images/section5bottombg.png'
import section5divdh from '../../images/section5divdh.png'
import footer_line from '../../images/footer_line.png'
import section5divdw from '../../images/section5divdw.png'
class Footer extends Component {
    render() {
        return (
            <div>
                <div className='section5'>
                    <img src={section5bottombg} className='section3img'></img>
                    <div className='section5divimg'>
                        <img src={footer_line}></img>
                    </div>
                    <div className='section5div'>
                        <div className='section5div2'>
                            <ul className='section5div2ul'>
                                <li>上海麦色信息科技有限公司</li>
                                <li className='footer_icon_main'> <img className='footer_icon' src={section5divdh}></img>  电话：021-32580010</li>
                                <li  className='footer_icon_main'><img  className='footer_icon' src={section5divdw}></img>  地址： 上海市闵行区宜山路1618号新意城A栋305室</li>
                                <li  className='footer_icon_main'>互联网药品信息服务:(沪)-非经营性-2019-0169</li>
                            </ul>
                        </div>
                        <div className='section5div3'>CopyRight 2022 上海麦色信息科技有限公司版权所有   <a target='_blank' style={{color:'white'}} href='https://beian.miit.gov.cn'>沪ICP备15027840号-1</a></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;